<template>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('salesCheques.salesCheques')"
                     :description="$t('salesCheques.fromHereYouCanControlThesalesCheques')"
                     />
         <indexTable :searchText="$t('salesCheques.FindACheck')"
                     :emptyTableText="$t('salesCheques.thereAreNosalesCheques')"
                     :emptyTableSubText="$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')"
                     :buttonRole="$user.role.sales_cheques_add"
                     :statusSearch="true"
                     :statusValues="[
                        { title: $t('salesCheques.active'), value: '' },
                        { title: $t('salesCheques.Waiting'), value: 0 },
                        { title: $t('salesCheques.driven'), value: 1 },
                      ]"
                     :cloumns="[
                         {column: 'code',    title: $t('salesCheques.batch') ,type: 'mainLink' , sort: true },
                         {column: 'client_type',    title: $t('salesCheques.customerName') ,type: 'clintLink', sort: true},
                         {column: 'safe_id',    title: $t('salesCheques.Treasury'),      type: 'link', to:'safe', sort: true, link: true},
                         {column: 'created_date',    title: $t('salesCheques.RegistrationDate') ,type: 'text' , sort: true },
                         {column: 'date',    title: $t('salesCheques.paymentDate') ,type: 'text' , sort: true },
                         {column: 'description',    title: $t('salesCheques.thatsAbout') ,type: 'text' , sort: true },
                         {column: 'cost',    title: $t('salesCheques.amount') ,type: 'text' , sort: true},
                         {column: 'stat',    title: $t('salesCheques.active') ,type: 'status', sort: true,  values: [
                           {title: $t('salesCheques.Waiting'), value: 0, color: 'info'},
                           {title: $t('salesCheques.driven'), value: 1, color: 'success'},
                           {title: $t('salesCheques.converted'), value: 2, color: 'success'},
                           {title: $t('salesCheques.refused'), value: 3, color: 'danger'}
                         ]},
                         {column: 'options', title: $t('salesCheques.settings'), type: 'options', options: [
                             {name: 'show'},
                             {name: 'printSalesCheque',   role: true},
                             {name: 'edit',   role: $user.admin || $user.role.sales_cheques_edit},
                             {name: 'file'},
                             {name: 'delete', role: $user.admin || $user.role.sales_cheques_delete},
                         ]}
                       ]"
                     :deleteText="{
                        attention: $t('allerts.Attention'),
                        areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
                        close: $t('allerts.close'),
                        confirm: $t('allerts.confirm'),
                        loading: $t('allerts.loading'),
                       }" />
      </div>
    </div>
  </div>

</template>
<script>

  import indexHeader from '../elements/index/indexHeader.vue'
  import indexTable from '../elements/index/indexTable.vue'

  export default {
    data() {
      return {
        path: '/salesCheques',
      }
    },
    components: {
      indexHeader,
      indexTable,
    }
  }

</script>
