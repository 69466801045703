var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('salesCheques.salesCheques'),"description":_vm.$t('salesCheques.fromHereYouCanControlThesalesCheques')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('salesCheques.FindACheck'),"emptyTableText":_vm.$t('salesCheques.thereAreNosalesCheques'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.sales_cheques_add,"statusSearch":true,"statusValues":[
                      { title: _vm.$t('salesCheques.active'), value: '' },
                      { title: _vm.$t('salesCheques.Waiting'), value: 0 },
                      { title: _vm.$t('salesCheques.driven'), value: 1 } ],"cloumns":[
                       {column: 'code',    title: _vm.$t('salesCheques.batch') ,type: 'mainLink' , sort: true },
                       {column: 'client_type',    title: _vm.$t('salesCheques.customerName') ,type: 'clintLink', sort: true},
                       {column: 'safe_id',    title: _vm.$t('salesCheques.Treasury'),      type: 'link', to:'safe', sort: true, link: true},
                       {column: 'created_date',    title: _vm.$t('salesCheques.RegistrationDate') ,type: 'text' , sort: true },
                       {column: 'date',    title: _vm.$t('salesCheques.paymentDate') ,type: 'text' , sort: true },
                       {column: 'description',    title: _vm.$t('salesCheques.thatsAbout') ,type: 'text' , sort: true },
                       {column: 'cost',    title: _vm.$t('salesCheques.amount') ,type: 'text' , sort: true},
                       {column: 'stat',    title: _vm.$t('salesCheques.active') ,type: 'status', sort: true,  values: [
                         {title: _vm.$t('salesCheques.Waiting'), value: 0, color: 'info'},
                         {title: _vm.$t('salesCheques.driven'), value: 1, color: 'success'},
                         {title: _vm.$t('salesCheques.converted'), value: 2, color: 'success'},
                         {title: _vm.$t('salesCheques.refused'), value: 3, color: 'danger'}
                       ]},
                       {column: 'options', title: _vm.$t('salesCheques.settings'), type: 'options', options: [
                           {name: 'show'},
                           {name: 'printSalesCheque',   role: true},
                           {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.sales_cheques_edit},
                           {name: 'file'},
                           {name: 'delete', role: _vm.$user.admin || _vm.$user.role.sales_cheques_delete} ]}
                     ],"deleteText":{
                      attention: _vm.$t('allerts.Attention'),
                      areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                      close: _vm.$t('allerts.close'),
                      confirm: _vm.$t('allerts.confirm'),
                      loading: _vm.$t('allerts.loading'),
                     }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }